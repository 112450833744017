import React from 'react'
import Footer from '../components/Footer'
import Nav from '../components/Nav'

import Hero from '../components/Hero'

import { StaticImage, getImage } from 'gatsby-plugin-image'
import { graphql, Link } from 'gatsby';

import { useMediaQuery } from 'react-responsive'

import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';


import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from "gbimage-bridge";

export default function About({data}) {
    const { content } = data.file;
    const focusAreas = data.file.content.focusAreas;
    const heroImage = data.hero.childImageSharp.gatsbyImageData;
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const affordableHousing = convertToBgImage(getImage(data.affordable_housing.childImageSharp.gatsbyImageData));
    const seniorLiving = convertToBgImage(getImage(data.senior_living.childImageSharp.gatsbyImageData));
    const infill = convertToBgImage(getImage(data.infill.childImageSharp.gatsbyImageData));
    const assetManagement = convertToBgImage(getImage(data.asset_management.childImageSharp.gatsbyImageData));

    return (
        <div>
            <Nav isActive="About"/>
            <Hero title={content.heroTitle} image={heroImage}/>
            <ParallaxProvider>
            <section className="px-6 py-7 md:py-10 bg-sphinx-background-white">
                <div className="container mx-auto space-y-6 max-w-6xl flex flex-wrap justify-center md:flex-nowrap md:justify-between md:space-y-0 md:space-x-3">
                    
                        <div className="space-y-4 md:space-y-6">
                            <h1 className="text-4xl font-light font-montserrat text-sphinx-accent">Our Mission</h1>
                            <h6 className="text-sm font-light font-sanspro text-gray-700 italic">{content.quote}</h6>
                            <p className="text-base font-sanspro text-gray-600 tracking-wide leading-loose max-w-3xl font-light">{content.missionStatement}</p>
                        </div>
                    
                        <Parallax className="custom-class" y={isMobile ? [0,0] : ["50px", "-50px"]} disabled={isMobile}>
                        <div className="relative flex max-w-xs">
                        
                            <StaticImage src="../images/Heros/leadership.jpg" width={300} aspectRatio={1/1}/>
                            <a href="/leadership" className="absolute -bottom-2.5 -right-5 bg-sphinx-accent leading-10 w-36 shadow-md text-white font-montserrat text-sm text-center">View Leadership</a>
                        </div>
                        </Parallax>
                </div>
            </section>
            </ParallaxProvider>

            <section className="bg-random-white">
                <div className="container mx-auto py-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 place-items place-content-center">
                    <div className="flex justify-center flex-wrap text-center lg:border-r-2 lg:border-sphinx-background-white py-3 px-8">
                        <h2 className="text-5xl font-thin text-sphinx-accent w-full">4</h2>
                        <p className="w-3/6 mt-3 text-sm font-light tracking-wide leading-7 uppercase text-random-dark">Projects under active development</p>
                    </div>

                    <div className="flex justify-center flex-wrap text-center lg:border-r-2 lg:border-sphinx-background-white py-3 px-8">
                        <h2 className="text-5xl font-thin text-sphinx-accent w-full">1650+</h2>
                        <p className="mt-3 text-sm font-light tracking-wide leading-7 uppercase text-random-dark">Apartment Homes</p>
                    </div>


                    <div className="flex justify-center flex-wrap text-center lg:border-r-2 lg:border-sphinx-background-white py-3 px-8">
                        <h2 className="text-5xl font-thin text-sphinx-accent w-full">3500+</h2>
                        <p className="mt-3 text-sm font-light tracking-wide leading-7 uppercase text-random-dark">Happy residents</p>
                    </div>

                    <div className="flex justify-center flex-wrap text-center lg:border-r-2 lg:border-sphinx-background-white  py-3 px-8">
                        <h2 className="text-5xl font-thin text-sphinx-accent w-full">25+</h2>
                        <p className="mt-3 text-sm font-light tracking-wide leading-7 uppercase text-random-dark">Years of success</p>
                    </div>

                    <div className="flex justify-center flex-wrap text-center py-3 px-8">
                        <h2 className="text-5xl font-thin text-sphinx-accent w-full">50+</h2>
                        <p className="mt-3 text-sm font-light tracking-wide leading-7 uppercase text-random-dark">Team Members</p>
                    </div>
                </div>
            </section>

            <div className="grid h-80 w-full">
                <StaticImage
                    style={{
                    gridArea: "1/1",
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                    }}
                    layout="fullWidth"
                    alt = "Image"
                    src = "../images/Properties/Alsbury Villas/Alsbury-Villas_1.jpg"
                    formats ={["auto", "webp", "avif"]}
                    ></StaticImage>

                <div
                    style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "center",
                    display: "grid",
                    }}
                    className=" bg-sphinx-blue bg-opacity-50"
                >
                    <div className="text-center">
                        <h1 className="text-5xl text-sphinx-white font-montserrat font-medium leading-relaxed">Building Homes, Not Houses</h1>
                    </div>
                </div>
            </div>

            <section className="py-12 bg-sphinx-background-white-2">
                <div className="px-6 container mx-auto space-y-4 max-w-7xl md:px-11">
                    <h1 className="text-4xl font-light font-montserrat text-sphinx-accent">Our Focus</h1>
                    <p className="text-base font-sanspro text-gray-600 tracking-wide leading-loose font-light">{content.ourFocus}</p>
                </div>
            </section>
            
            <section class="shadow-sm">
                    <div className="grid grid-cols-1 md:grid-cols-2 place-items-center">
                        <div className="h-full w-full">
                            <BackgroundImage Tag="section" className="h-52" style={{ minWidth: '100%', minHeight: '100%' }} {...affordableHousing} backgroundColor={"#FFFFFF"} preserveStackingContext>
                            </BackgroundImage>
                        </div>
                        
                        <div className="py-12 md:py-24 px-12 space-y-3 md:space-y-6 max-w-prose">
                            <h1 className="text-2xl md:text-4xl font-light font-montserrat text-sphinx-accent">{focusAreas[0].name}</h1>
                            <h5 className="text-lg md:text-xl font-light font-sanspro text-gray-700 md:mt-3">{focusAreas[0].subTitle}</h5>
                            <p className="text-base font-sanspro text-gray-600 tracking-wide leading-loose font-light md:mt-6">{focusAreas[0].description}</p>
                            <Link to="/portfolio" className="text-sm text-center mt-5 p-2 max-w-max text-sphinx-accent font-montserrat border-sphinx-accent border-2 flex items-center hover:bg-sphinx-accent hover:text-random-white hover:shadow-lg">View Properties</Link>
                        </div>
                    </div>
            </section>

            <section>
                    <div className="grid grid-cols-1 md:grid-cols-2 place-items-center">
                        <div className="h-full w-full">
                            <BackgroundImage Tag="section" className="h-52" style={{ minWidth: '100%', minHeight: '100%' }} {...seniorLiving} backgroundColor={"#FFFFFF"} preserveStackingContext>
                            </BackgroundImage>
                        </div>
                        
                        <div className="py-12 md:py-24 px-12 space-y-3 md:space-y-6 col-start-1 md:row-start-1 max-w-prose justify-self-center">
                            <h1 className="text-2xl md:text-4xl font-light font-montserrat text-sphinx-accent">{focusAreas[1].name}</h1>
                            <h5 className="text-lg md:text-xl font-light font-sanspro text-gray-700">{focusAreas[1].subTitle}</h5>
                            <p className="text-base font-sanspro text-gray-600 tracking-wide leading-loose font-light">{focusAreas[1].description}</p>
                            <Link to="/portfolio" className="text-sm text-center mt-5 p-2 max-w-max text-sphinx-accent font-montserrat border-sphinx-accent border-2 flex items-center hover:bg-sphinx-accent hover:text-random-white hover:shadow-lg" state={{ filter: 'senior' }}>View Senior Communities</Link>
                        </div>
                    </div>
            </section>

            <section class="shadow-sm">
                    <div className="grid grid-cols-1 md:grid-cols-2 place-items-center">
                        <div className="h-full w-full">
                            <BackgroundImage Tag="section" className="h-52" style={{ minWidth: '100%', minHeight: '100%' }} {...infill} backgroundColor={"#FFFFFF"} preserveStackingContext>
                            </BackgroundImage>
                        </div>
                        
                        <div className="py-12 md:py-24 px-12 space-y-3 md:space-y-6 max-w-prose">
                            <h1 className="text-2xl md:text-4xl font-light font-montserrat text-sphinx-accent">{focusAreas[2].name}</h1>
                            <h5 className="text-lg md:text-xl font-light font-sanspro text-gray-700 md:mt-3">{focusAreas[2].subTitle}</h5>
                            <p className="text-base font-sanspro text-gray-600 tracking-wide leading-loose font-light md:mt-6">{focusAreas[2].description}</p>
                            <Link to="/portfolio" className="text-sm text-center mt-5 p-2 max-w-max text-sphinx-accent font-montserrat border-sphinx-accent border-2 flex items-center hover:bg-sphinx-accent hover:text-random-white hover:shadow-lg">Learn More</Link>
                        </div>
                    </div>
            </section>

            <section>
                <div className="grid grid-cols-1 md:grid-cols-2 place-items-center">
                    <div className="h-full w-full">
                        <BackgroundImage Tag="section" className="h-52" style={{ minWidth: '100%', minHeight: '100%' }} {...assetManagement} backgroundColor={"#FFFFFF"} preserveStackingContext>
                        </BackgroundImage>
                    </div>
                    
                    <div className="py-12 md:py-24 px-12 space-y-3 md:space-y-6 col-start-1 md:row-start-1 max-w-prose justify-self-center">
                        <h1 className="text-2xl md:text-4xl font-light font-montserrat text-sphinx-accent">{focusAreas[3].name}</h1>
                        <h5 className="text-lg md:text-xl font-light font-sanspro text-gray-700">{focusAreas[3].subTitle}</h5>
                        <p className="text-base font-sanspro text-gray-600 tracking-wide leading-loose font-light">{focusAreas[3].description}</p>
                    </div>
                </div>
            </section>



            <section className="py-12 bg-sphinx-background-white">
                <div className="px-6 container mx-auto flex flex-col items-center space-y-3">
                    <div className="block space-y-4 max-w-6xl md:px-11">
                        <h1 className="text-3xl font-light font-montserrat text-sphinx-accent text-center sm:text-4xl">Acknowledgements</h1>
                        <p className="text-base font-light font-sanspro text-gray-600 tracking-wide leading-loose">Sphinx Development Corporation would like to acknowledge and give thanks to all of our partners located across the state of Texas, our partners in the country of Africa, and every person we can proudly call an SDC community member.</p>
                    </div>
                    <div className="w-full grid grid-cols-2 place-items-center place-content-between gap-4 lg:w-3/4 lg:grid-cols-3">
                        <div>
                           <StaticImage src="../images/ack/ack-1.png" width={200}/>
                        </div>
                        <div >
                           <StaticImage src="../images/ack/ack-2.png" width={200}/>
                        </div>
                        <div>
                           <StaticImage src="../images/ack/ack-3.png" width={200}/>
                        </div>
                        {/* <div>
                           <StaticImage src="../images/ack/bank-of-america-logo.png" width={200}/>
                        </div>
                        <div>
                           <StaticImage src="../images/ack/novo.png" width={200}/>
                        </div>
                        <div>
                           <StaticImage src="../images/ack/sprite-molly.png" width={200}/>
                        </div>
                        <div>
                           <StaticImage src="../images/ack/sunamerica-logo.png" width={200}/>
                        </div>
                        <div>
                           <StaticImage src="../images/ack/tdhca.png" width={200}/>
                        </div>
                        <div>
                           <StaticImage src="../images/ack/taahp-logo.png" width={200}/>
                        </div>
                        <div>
                           <StaticImage src="../images/ack/wells-fargo-logo.png" width={200}/>
                        </div> */}
                    </div>

                    {/* <Link to="/ventures" className="text-sm text-center mt-5 p-2 max-w-max text-sphinx-accent font-montserrat border-sphinx-accent border-2 flex items-center hover:bg-sphinx-accent hover:text-random-white hover:shadow-lg">View Complete List</Link> */}
                    
                </div>
                        
            </section>

            <section className="py-12 bg-sphinx-background-dark bg-opacity-20">
                <div className="px-6 container mx-auto flex flex-wrap justify-center space-y-3">
                    <div className="block space-y-4 max-w-6xl md:px-11">
                        <h1 className="text-3xl font-light font-montserrat text-sphinx-accent text-center sm:text-4xl">Providing A Wide Range of Property-Related Services</h1>
                        <p className="text-base font-light font-sanspro text-gray-600 tracking-wide leading-loose">The Sphinx Development is a leader of companies with expertise across the full spectrum of the real estate industry; development, construction, and property management. Vertically integrated, Sphinx platform provides the capabilities to successfully execute in all aspects of our business - development, acquisition, finance, asset management, property management, construction management, marketing, leasing, accounting, financial reporting, legal, risk management, information technology and human resources.</p>
                    </div>
                   
                </div>     
            </section>

            {/* Links to sub pages Section */}
            <div className="md:flex">
                <a href="/development" className="flex-shrink w-full h-80 grid group">
                    <StaticImage 
                        style={{gridArea: "1/1"}}
                        layout="fullWidth"
                        aspectRatio={1/1}
                        alt="Background Image"
                        src={"../images/about/development.jpg"}
                        formats={["webp", "avif"]}
                    />
                    <div
                        style={{
                        gridArea: "1/1",
                        position: "relative",
                        placeItems: "start",
                        display: "grid",
                        }}
                        className="bg-sphinx-blue bg-opacity-20 px-5 pt-7 transition duration-300 group-hover:bg-opacity-50 group-hover:bg-sphinx-accent">
                            <div className="transition duration-300 ease-in-out text-sphinx-white text-xl font-montserrat font-light overflow-hidden lg:text-2xl">
                                <p className="min-w-max tracking-wide">Sphinx <span className="font-medium">Development</span></p>
                                <div className="my-2 h-1 w-0 bg-sphinx-white transition-all duration-300 group-hover:w-full"></div>
                                <p className="text-base transform translate-x-full transition duration-300 group-hover:translate-x-0">Learn more about SDC Development</p>
                            </div>
                    </div>
                </a>
                <a href="/construction" className="flex-shrink w-full h-80 grid group">
                    <StaticImage 
                        style={{gridArea: "1/1"}}
                        layout="fullWidth"
                        aspectRatio={1/1}
                        alt="Background Image"
                        src={"../images/about/construction.jpg"}
                        formats={["webp", "avif"]}
                    />
                    <div
                        style={{
                        gridArea: "1/1",
                        position: "relative",
                        placeItems: "start",
                        display: "grid",
                        }}
                        className="bg-sphinx-blue bg-opacity-20 px-5 pt-7 transition duration-300 group-hover:bg-opacity-50 group-hover:bg-sphinx-accent">
                            <div className="transition duration-300 ease-in-out text-sphinx-white text-xl font-montserrat font-light overflow-hidden lg:text-2xl">
                                <p className="min-w-max tracking-wide">SDC <span className="font-medium">Construction</span></p>
                                <div className="my-2 h-1 w-0 bg-sphinx-white transition-all duration-300 group-hover:w-full"></div>
                                <p className="text-base transform translate-x-full transition duration-300 group-hover:translate-x-0">Learn more about SDC Construction</p>
                            </div>
                    </div>
                </a>

                <a href="/ventures" className="flex-shrink w-full h-80 grid group">
                    <StaticImage 
                        style={{gridArea: "1/1"}}
                        layout="fullWidth"
                        aspectRatio={3/1}
                        alt="Background Image"
                        src={"../images/about/ventures.jpg"}
                        formats={["webp", "avif"]}
                    />
                    <div
                        style={{
                        gridArea: "1/1",
                        position: "relative",
                        placeItems: "start",
                        display: "grid",
                        }}
                        className="bg-sphinx-blue bg-opacity-20 px-5 pt-7 transition duration-300 group-hover:bg-opacity-50 group-hover:bg-sphinx-accent">
                            <div className="transition duration-300 ease-in-out text-sphinx-white text-xl font-montserrat font-light overflow-hidden lg:text-2xl">
                                <p className="min-w-max tracking-wide">Joint <span className="font-medium">Ventures</span></p>
                                <div className="my-2 h-1 w-0 bg-sphinx-white transition-all duration-300 group-hover:w-full"></div>
                                <p className="text-base transform translate-x-full transition duration-300 group-hover:translate-x-0">Learn more about how Sphinx is invloved with other major corporations</p>
                            </div>
                    </div>
                </a>
            </div>
            <Footer/>
        </div>
    )
}

export const pageQuery = graphql`
  query AboutPageContent {
    file(relativePath: {eq: "pages/about.yml"}) {
        content: childPagesYaml {
            heroTitle
            missionStatement
            ourFocus
            focusAreas {
                description
                name
                subTitle
              }
        }
    }

    hero: file(relativePath: {eq: "Heros/team.jpg"}) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
        }
    }
    affordable_housing: file(relativePath: {eq: "affordableHousing.jpg"}) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
    } 
    senior_living: file(relativePath: {eq: "senior-living.jpg"}) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
    }
    infill: file(relativePath: {eq: "infill-development.jpg"}) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
    }
    asset_management: file(relativePath: {eq: "Properties/Harmon Villas/harmon-villas-2.jpg"}) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
    }
  }
`